import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import moment from "moment-timezone";
import cloneDeep from "lodash/cloneDeep";
import spinner from "./assets/spinner.gif";

import "./Order.css";

class Order extends React.Component {
  constructor(props) {
    super(props);

    props.order.rate = props.order.rates?.find(
      (rate) => rate.id === props.order.shiphawk_rate_id
    );

    this.state = {
      dropdownStyle: this.getDropdownStyle(props.order.order_status.id),
      isModalOpen: false,
      bomCopy: [],
      description: this.props.order.description || "",
      combineWith: this.props.order.combine_with || "",
      customerNotes: this.props.order.customer_notes || "",
      isRating: false,
      isSendingOrder: false,
    };
  }

  // Updates the mutated props from order to orders back to order
  componentWillReceiveProps(nextProps) {
    this.setState({
      dropdownStyle: this.getDropdownStyle(nextProps.order.order_status.id),
      description: nextProps.order.description || "",
      combineWith: nextProps.order.combine_with || "",
      customerNotes: nextProps.order.customer_notes || "",
    });
  }

  getDropdownStyle(orderStatusId) {
    if (orderStatusId == 1) {
      return "danger";
    } else if (orderStatusId == 2) {
      return "primary";
    } else if (orderStatusId == 3) {
      return "dark-blue";
    } else if (orderStatusId == 4) {
      return "warning";
    } else if (orderStatusId == 5) {
      return "success";
    } else if (orderStatusId == 6) {
      return "dark-green";
    } else if (orderStatusId == 7) {
      return "white";
    } else if (orderStatusId == 8) {
      return "white";
    } else if (orderStatusId == 9) {
      return "warning";
    } else if (orderStatusId == 10) {
      return "warning";
    } else if (orderStatusId == 11) {
      return "warning";
    } else if (orderStatusId == 12) {
      return "primary";
    } else if (orderStatusId == 13) {
      return "primary";
    } else if (orderStatusId == 14) {
      return "warning";
    } else if (orderStatusId == 15) {
      return "success";
    }
  }

  openBomModal = () => {
    if (!this.state.bomCopy.length) {
      this.setState({
        bomCopy: cloneDeep(this.props.order.boms),
      });
    }

    this.setState({
      isModalOpen: true,
    });
  };

  closeBomModal = () => {
    this.props.order.has_bom_override = false;

    this.setState({
      isModalOpen: false,
      bomCopy: [],
    });
  };

  saveBomOverrides = (idx) => {
    this.props.order.boms = cloneDeep(this.state.bomCopy);
    this.setState({
      isModalOpen: false,
      bomCopy: [],
    });

    this.props.saveBomOverrides(idx);
  };

  removeBomItem = (idx) => {
    const bomCopy = this.state.bomCopy;
    bomCopy.splice(idx, 1);

    this.props.order.has_bom_override = true;

    this.setState({ bomCopy });
  };

  addBomItem = () => {
    const bomCopy = this.state.bomCopy;
    bomCopy.push({ quantity: null, sku: null });

    this.setState({ bomCopy });
  };

  onBomQuantityChange = (idx, e) => {
    const bomCopy = this.state.bomCopy;
    const bomItem = bomCopy[idx];
    bomItem.quantity = e.target.value ? parseInt(e.target.value) : "";
    bomItem.is_sub = false;
    bomItem.is_missing = false;

    this.props.order.has_bom_override = true;

    this.setState({ bomCopy });
  };

  onBomSkuChange = (idx, e) => {
    const bomCopy = this.state.bomCopy;
    const bomItem = bomCopy[idx];
    bomItem.sku = e.target.value;
    bomItem.is_sub = false;
    bomItem.is_missing = false;

    this.props.order.has_bom_override = true;

    this.setState({ bomCopy });
  };

  onBomCoreSkuChange = (idx, e) => {
    const bomCopy = this.state.bomCopy;
    const bomItem = bomCopy[idx];
    bomItem.core_sku = e.target.value;
    bomItem.is_sub = false;
    bomItem.is_missing = false;

    this.props.order.has_bom_override = true;

    this.setState({ bomCopy });
  };

  selectOrderStatus = (idx, orderStatus) => {
    this.setState({ dropdownStyle: this.getDropdownStyle(orderStatus.id) });

    this.props.selectOrderStatus(idx, orderStatus);
  };

  fetchRates = async (idx) => {
    this.setState({ isRating: true });

    await this.props.fetchRates(idx);

    this.setState({ isRating: false });
  };

  sendOrderToShiphawk = async (idx) => {
    this.setState({ isSendingOrder: true });
    await this.props.sendOrderToShiphawk(idx);
    this.setState({ isSendingOrder: false });
  };

  generateSkuClass(order, order_item) {
    if (order_item.component?.sku?.includes("DROPSHIP")) {
      return "order-bom-dropship";
    } else if (order.skus_missing_boms.includes(order_item.component.sku)) {
      return "order-bom-missing";
    } else {
      return "";
    }
  }

  generateBomClass(bom) {
    let classes = "";
    if (bom?.sku?.includes("DROPSHIP")) {
      classes += "order-bom-dropship ";
    }

    if (bom.is_sub) {
      classes += "order-bom-sub ";
    }

    if (bom.is_missing) {
      classes += "order-bom-missing ";
    }

    return classes;
  }

  render() {
    return (
      <tr>
        {this.props.isAllocationMode && (
          <td>
            <i
              className="fa fa-remove order-ignore"
              aria-hidden="true"
              onClick={(e) => this.props.ignoreOrder(this.props.idx, e)}
            ></i>
          </td>
        )}
        {this.props.isAllocationMode && (
          <td>
            <div>
              <i
                className="fa fa-arrow-circle-up order-arrow"
                aria-hidden="true"
                onClick={(e) => this.props.moveOrderTop(this.props.idx, e)}
              ></i>
            </div>
            <div>
              <i
                className="fa fa-arrow-up order-arrow"
                aria-hidden="true"
                onClick={(e) => this.props.moveOrderUp(this.props.idx, e)}
              ></i>
            </div>
            <div>
              <i
                className="fa fa-arrow-down order-arrow"
                aria-hidden="true"
                onClick={(e) => this.props.moveOrderDown(this.props.idx, e)}
              ></i>
            </div>
            <div>
              <i
                className="fa fa-arrow-circle-down order-arrow"
                aria-hidden="true"
                onClick={(e) => this.props.moveOrderBottom(this.props.idx, e)}
              ></i>
            </div>
          </td>
        )}
        {this.props.isAllocationMode && (
          <td>
            <input
              className="order-combine-with-input"
              value={this.state.combineWith}
              onChange={(e) =>
                this.props.updateCombineWithOrderNumber(this.props.idx, e)
              }
            ></input>
          </td>
        )}
        <td>{this.props.order.cust_ref}</td>
        <td>
          <div>
            {moment
              .utc(this.props.order.ordered_at)
              .tz("America/New_York")
              .format("MM/DD/YY H:mm")}
          </div>
        </td>
        <td>
          <DropdownButton
            size="sm"
            variant={this.state.dropdownStyle}
            title={this.props.order.order_status.name}
          >
            {this.props.orderStatusesOptions.map((orderStatus) => {
              return (
                <Dropdown.Item
                  key={orderStatus.id}
                  onClick={() =>
                    this.selectOrderStatus(this.props.idx, orderStatus)
                  }
                >
                  {orderStatus.id} - {orderStatus.name}
                </Dropdown.Item>
              );
            })}
          </DropdownButton>
        </td>
        <td>
          <DropdownButton
            as={ButtonGroup}
            size="sm"
            variant="primary"
            title={this.props.order.priority}
            className="order-priority"
          >
            <Dropdown.Item
              key={4}
              onClick={() => this.props.selectPriority(this.props.idx, 4)}
            >
              4
            </Dropdown.Item>
            <Dropdown.Item
              key={5}
              onClick={() => this.props.selectPriority(this.props.idx, 5)}
            >
              5
            </Dropdown.Item>
            <Dropdown.Item
              key={6}
              onClick={() => this.props.selectPriority(this.props.idx, 6)}
            >
              6
            </Dropdown.Item>
          </DropdownButton>
        </td>
        <td>
          <ul className="order-list">
            {this.props.order.order_items.map((item, idx) => {
              return (
                <li
                  key={idx}
                  className={this.generateSkuClass(this.props.order, item)}
                >
                  {item.quantity}x {item.component.sku}
                </li>
              );
            })}
          </ul>
        </td>
        <td className={this.props.order.final_boms ? "order-final-bom" : ""}>
          <ul className="order-list order-boms" onClick={this.openBomModal}>
            {(this.state.bomCopy.length
              ? this.state.bomCopy
              : this.props.order.boms
            ).map((bom, idx) => {
              return (
                <li key={idx} className={this.generateBomClass(bom)}>
                  {bom.quantity}x {bom.sku} / {bom.core_sku || "NULL"} (
                  {bom.inventory_before_bom})
                </li>
              );
            })}
          </ul>
        </td>

        <td>
          <ul className="order-list order-boms">
            {this.props.order.liner_boms.map((bom, idx) => {
              return (
                <li key={idx} className={this.generateBomClass(bom)}>
                  {bom.quantity}x {bom.sku} ({bom.inventory_before_bom})
                </li>
              );
            })}
          </ul>
        </td>
        <td>
          <DropdownButton
            as={ButtonGroup}
            size="sm"
            variant="primary"
            title={this.props.order.warehouse.name}
          >
            <Dropdown.Item
              key={"champion"}
              onClick={() =>
                this.props.selectWarehouse(this.props.idx, "champion")
              }
            >
              Champion
            </Dropdown.Item>
            <Dropdown.Item
              key={"tighe"}
              onClick={() =>
                this.props.selectWarehouse(this.props.idx, "tighe")
              }
            >
              Tighe
            </Dropdown.Item>
            <Dropdown.Item
              key={"3pllinks"}
              onClick={() =>
                this.props.selectWarehouse(this.props.idx, "3pllinks")
              }
            >
              3PL Links
            </Dropdown.Item>
            <Dropdown.Item
              key={"donarra"}
              onClick={() =>
                this.props.selectWarehouse(this.props.idx, "donarra")
              }
            >
              Donarra
            </Dropdown.Item>
            <Dropdown.Item
              key={"ultrabase"}
              onClick={() =>
                this.props.selectWarehouse(this.props.idx, "ultrabase")
              }
            >
              Ultrabase
            </Dropdown.Item>
          </DropdownButton>
        </td>
        {/* <td>
          {this.props.carriers.length && (
            <DropdownButton
              as={ButtonGroup}
              size="sm"
              variant="primary"
              title={this.props.order.carrier?.name || "Select"}
            >
              {this.props.carriers.map((carrier, idx) => {
                return (
                  <Dropdown.Item
                    key={carrier.name}
                    onClick={() =>
                      this.props.selectCarrier(this.props.idx, carrier)
                    }
                  >
                    {carrier.name}
                  </Dropdown.Item>
                );
              })}
            </DropdownButton>
          )}
        </td> */}
        {/* {!this.props.isAllocationMode && (
          <td>
            {this.state.isSendingOrder || this.state.isRating ? (
              <img className="spinner" src={spinner} alt="" />
            ) : (
              <div>
                <Button
                  className="fetch-rates-button"
                  size="sm"
                  onClick={() => this.fetchRates(this.props.idx)}
                >
                  Get Rates
                </Button>

                <Button
                  variant="dark-blue"
                  size="sm"
                  onClick={() => this.sendOrderToShiphawk(this.props.idx)}
                >
                  Send to Shiphawk
                </Button>
              </div>
            )}

            {this.props.order?.rates?.length && (
              <DropdownButton
                as={ButtonGroup}
                size="sm"
                variant="secondary"
                title={
                  this.props.order.rate
                    ? `$${this.props.order.rate.price} [${this.props.order.rates[0].rate_detail.proposed_shipments[0]?.warehouse_code}] - ${this.props.order.rate.carrier} - ${this.props.order.rate.service_name} - (${this.props.order.rate.service_days} day)`
                    : `$${this.props.order.rates[0].price} [${this.props.order.rates[0].rate_detail.proposed_shipments[0]?.warehouse_code}] - ${this.props.order.rates[0].carrier} - ${this.props.order.rates[0].service_name} - (${this.props.order.rates[0].service_days} day)`
                }
              >
                {this.props.order.rates.map((rate, idx) => {
                  return (
                    <Dropdown.Item
                      key={rate.id}
                      onClick={() =>
                        this.props.selectRate(this.props.idx, rate)
                      }
                    >
                      {`$${rate.price} [${this.props.order.rates[0].rate_detail.proposed_shipments[0]?.warehouse_code}] - ${rate.carrier} - ${rate.service_name} - (${rate.service_days})`}
                    </Dropdown.Item>
                  );
                })}
              </DropdownButton>
            )}
          </td>
              )} */}
        {!this.props.isAllocationMode && (
          <td>
            <div>
              — <span className="order-destination-label">Destination</span>:{" "}
              {this.props.order.ship_country}, {this.props.order.ship_zip}
            </div>
            {/* <div>
              —{" "}
              <span className="order-destination-label">Smartpack Result</span>:
              {this.props.order.rates
                ? (
                    this.props.order.rate?.rate_detail?.proposed_shipments?.[0]?.packages?.map(
                      (p) => p.weight + "lbs"
                    ) ||
                    this.props.order.rates[0]?.rate_detail?.proposed_shipments?.[0]?.packages?.map(
                      (p) => p.weight + "lbs"
                    )
                  )?.join(",")
                : "Get rates to see result"}
            </div> */}
            <div>
              — <span className="order-destination-label">Shipping Amount</span>
              : {this.props.order.shipping_handling}
            </div>
            <div>
              — <span className="order-destination-label">Shipping Mode</span>:{" "}
              {this.props.order.shipping_mode}
            </div>
          </td>
        )}
        {!this.props.isAllocationMode && (
          <td>
            {this.state.isModalOpen && (
              <div className="popup" id="popup-dialog">
                <div className="popup-content order-modal-content" id="content">
                  <div className="popup-content-header order-modal-header">
                    <div className="order-modal-header-title">
                      BOM Override Menu
                    </div>
                    <Button variant="danger" onClick={this.closeBomModal}>
                      X
                    </Button>
                  </div>
                  <div className="popup-content-container order-modal-content-container">
                    {this.state.bomCopy.map((bom, idx) => {
                      return (
                        <div key={idx} className="order-modal-item">
                          <input
                            className="order-modal-item-quantity"
                            value={bom.quantity}
                            onChange={(e) => this.onBomQuantityChange(idx, e)}
                          ></input>
                          <input
                            className="order-modal-item-sku space-left"
                            value={bom.sku}
                            onChange={(e) => this.onBomSkuChange(idx, e)}
                          ></input>
                          <input
                            className="order-modal-item-core-sku space-left"
                            value={bom.core_sku}
                            onChange={(e) => this.onBomCoreSkuChange(idx, e)}
                          ></input>
                          <Button
                            variant="danger"
                            className="order-modal-delete-btn"
                            onClick={() => this.removeBomItem(idx)}
                          >
                            —
                          </Button>
                        </div>
                      );
                    })}

                    <Button
                      className="order-modal-add-btn"
                      variant="success"
                      onClick={this.addBomItem}
                    >
                      +
                    </Button>
                    <Button
                      className="order-modal-save-btn"
                      variant="warning"
                      onClick={() => this.saveBomOverrides(this.props.idx)}
                    >
                      Override
                    </Button>
                  </div>
                </div>
              </div>
            )}
            <div className="form-check order-check">
              <input
                className="form-check-input"
                type="checkbox"
                onClick={(e) => this.props.toggleSend(this.props.idx, e)}
              />
            </div>
          </td>
        )}
        {!this.props.isAllocationMode && (
          <td>
            {this.props.order.warehouse_status.step} -{" "}
            {this.props.order.warehouse_status.name}
          </td>
        )}
        {/* <td>{this.props.order.ship_country}</td> */}
        <td>
          <textarea
            className="form-control order-desc"
            rows="3"
            value={this.state.description}
            onChange={(e) => this.props.updateDescription(this.props.idx, e)}
          ></textarea>
        </td>
        <td>
          <textarea
            className="form-control order-desc"
            rows="3"
            value={this.state.customerNotes || this.props.order.wup_description}
            onChange={(e) => this.props.updateCustomerNotes(this.props.idx, e)}
          ></textarea>
        </td>
        <td>
          <div>
            {moment
              .utc(this.props.order.updated_at)
              .tz("America/New_York")
              .format("MM/DD/YY H:mm")}
          </div>
        </td>
      </tr>
    );
  }
}

export default Order;
